<template>
  <v-app>
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      v-if="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          <h2 class="white--text">Loading</h2>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>

        <v-btn
          @click.stop="
            Export2Doc('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1"
          color="blue"
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
        <v-btn
          @click.stop="
            exportToExcel('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1"
          color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <div align="center">
          <v-img
            max-height="30mm"
            max-width="25mm"
            src="krud.jpg"
          ></v-img>
          <div class="head">คำสั่งสำนักงานคณะกรรมการการอาชีวศึกษา</div>
          <div>
            ที่....................../2566
          </div>
          <div class="head">
            เรื่อง
            การแต่งตั้งคณะกรรมการรับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและคะแนนในการประเมิน
            <br />
            ตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู
            (การย้ายกรณีปกติ) <br />
            สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          </div>

          <div>___________________________</div>
        </div>

        <div class="divTab">
          ตามที่ สำนักงานคณะกรรมการการอาชีวศึกษา
          มีกำหนดการย้ายข้าราชการครูและบุคลากรทางการศึกษา ตำแหน่งครู ประจำปี
          ครั้งที่ 1/2566 ตามนัยหนังสือสำนักงานคณะกรรมการการอาชีวศึกษา ที่ ศธ
          0601/360 ลงวันที่ 9 มกราคม 2566 นั้น
        </div>
        <div class="divTab">
          เพื่อให้การดำเนินการย้ายข้าราชการครูและบุคลากรทางการศึกษา ตำแหน่งครู
          สังกัดสำนักงานคณะกรรมการการอาชีวศึกษาเป็นไปด้วยความเรียบร้อย
          จึงแต่งตั้งคณะกรรมการรับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและคะแนนในการประเมินตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
          ตำแหน่งครู (การย้ายกรณีปกติ) สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          เพื่อดำเนินการรับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและคะแนนในการประเมินตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
          ตำแหน่งครู (การย้ายกรณีปกติ) สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          ตามหลักเกณฑ์และวิธีการที่ ก.ค.ศ. กำหนดประกอบด้วยคณะกรรมการ ดังต่อไปนี้
        </div>

        <div class="divbold lineSpace15">
          คณะกรรมการรับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและคะแนนในการประเมินตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
          ตำแหน่งครู (การย้ายกรณีปกติ) สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
        </div>
        <div
          v-for="(item, index) in commiteevecreports"
          :key="item.province_no"
        >
          <span class="bold16"
            >กลุ่มที่ {{ index + 1 }} {{ item.province_name }}</span
          >
          <table class="text_j" width="100%" align="center">
            <tr v-for="child in item.commiteevecpros" :key="child.province_no">
              <td width="5%" class="text-center tdTop"></td>
              <td width="18%" class="pl-1 tdTop">
                {{ child.commiteeVecProPosition }}.{{ child.title_s
                }}{{ child.frist_name }}
              </td>
              <td width="17%" class="tdTop">{{ child.last_name }}</td>
              <td width="40%">
                <span v-if="child.position_name === 'ผู้อำนวยการวิทยาลัย'"
                  >ผู้อำนวยการ</span
                >
                <span
                  v-else-if="child.position_name === 'รองผู้อำนวยการวิทยาลัย'"
                  >รองผู้อำนวยการ</span
                ><span v-else>{{ child.position_name }}</span
                >{{ child.college_name }} <br />
                <span v-if="child.commiteeVecProPosition === '1'">
                  ทำหน้าที่ประธานกรรมการอาชีวศึกษา{{ item.province_name }}</span
                >
                <span v-if="child.commiteeVecProPosition === '6'">
                  ทำหน้าที่เลขานุการกรรมการอาชีวศึกษา{{
                    item.province_name
                  }}</span
                >
              </td>
              <td width="20%" class="tdTop">
                <span v-if="child.commiteeVecProPosition === '1'">
                  ประธานกรรมการ</span
                >
                <span v-if="child.commiteeVecProPosition === '2'">
                  กรรมการ</span
                >
                <span v-if="child.commiteeVecProPosition === '3'">
                  กรรมการ</span
                >
                <span v-if="child.commiteeVecProPosition === '4'">
                  กรรมการ</span
                >
                <span v-if="child.commiteeVecProPosition === '5'">
                  กรรมการ</span
                >
                <span v-if="child.commiteeVecProPosition === '6'">
                  เลขานุการ</span
                >
              </td>
            </tr>
          </table>
        </div>

        <div class="divbold">
          ให้คณะกรรมการมีหน้าที่ดังนี้
        </div>
        <div class="divTab50">
          1.
          รับเอกสารและตรวจสอบองค์ประกอบตัวชี้วัดและให้คะแนนประเมินตามองค์ประกอบการประเมินศักยภาพของผู้ประสงค์ขอย้าย
          ตำแหน่งครู (การย้ายกรณีปกติ) สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          ยกเว้นตัวชี้วัดที่ 1.4 ขององค์ประกอบที่ 1
        </div>
        <div class="divTab50">
          2. ปฏิบัติหน้าที่อื่น ตามที่สำนักงานคณะกรรมการการอาชีวศึกษามอบหมาย
        </div>
        <div class="divTab lineSpace15">
          ทั้งนี้ ตั้งแต่บัดนี้เป็นต้นไป
        </div>
        <div class="text-center lineSpace15">
          สั่ง  ณ  วันที่          กุมภาพันธ์ พ.ศ. 2566
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    commiteevecreports: []
  }),

  async mounted() {
    
await this.sweetAlertLoading()
    await this.commiteevecreportsQueryAll();
  },

  methods: {
    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },


    async commiteevecreportsQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("commiteevecreport.php", {
          ApiKey: this.ApiKey,
          commiteevecreportYear: this.years,
          commiteevecreportTime: this.times,
          showGroupProvince: "OK"
        })
        .finally(() => (this.loading = false));
      this.commiteevecreports = result.data;
      
Swal.close();
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    years() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    times() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[7];
    },
    date_today() {
      let today = new Date();
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.div_j {
  border-collapse: collapse;
  text-justify: inter-word;
  line-height: 1;
}
.divTab {
  text-justify: inter-word;
  text-indent: 100px;
  line-height: 1;
}
.divTab50 {
  text-justify: inter-word;
  text-indent: 50px;
  line-height: 1;
}
.lineSpace15{
line-height: 1.5;
}
.divbold {
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}
.tdTop {
  vertical-align: top;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}
p {
  text-indent: 50px;
}
td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 0.5cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.bold14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}
.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
              height: 247mm;
            }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}
.text_al {
  text-align: left;
  vertical-align: top;
}
#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}
.tab1 {
  padding-left: 4em;
}
.textAlignVer {
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  margin-bottom: 2px;
}
@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
                font-size: 18px;
                font-weight: bold;
              }
              .regular12 {
                font-size: 12px;
              }
              .blod12 {
                font-size: 12px;
                font-weight: bold;
              }
              .blackRegula8 {
                font-size: 8px;
              } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
